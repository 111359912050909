.ai-stream-chunk {
  opacity: 0;
  transform: translateY(10px);
  animation: reveal 1000ms ease-in-out forwards;
}

@keyframes reveal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 800px) {
  .quiz-modal {
    .question-container {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      .left-panel {
        background-color: var(--color-bg-prim-w-110);

        .autoplay-switch {
          height: 28px;
          left: 15px;
          margin-bottom: 0;
          margin-left: 0;
          position: absolute;
          top: 14px;
          width: 44px;

          .text-subheading-5 {
            display: none;
          }
        }

        .progress-container {
          margin: 11px 129px 0 129px;
          width: auto;
        }

        .question-body {
          align-items: center;
          flex-direction: column;
          gap: 12px;
          margin-top: 32px;

          .question-animation {
            margin-left: 0;
          }

          .question-text {
            align-items: center;
            align-self: stretch;
            margin-top: -24px;
            text-align: center;

            .text-heading-1 {
              font-size: 24px;
              line-height: 32px;
            }
          }
        }
      }

      .right-panel {
        align-items: center;
        align-self: stretch;
        flex-direction: column;
        justify-content: center;
        padding: 0 134px;

        > div {
          justify-content: flex-start;
          margin-top: auto;
          width: 100%;

          > div {
            justify-content: flex-start;
          }
        }

        .answers-feedback {
          display: none;
        }

        .answers-container {
          max-width: none;
        }
      }
    }
  }
}
